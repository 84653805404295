import React from 'react';
import { Image, Text } from 'theme-ui';
import Layout from '../components/layout';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { SRLWrapper } from 'simple-react-lightbox';
import styled from '@emotion/styled';

const Img = styled(Image)`
  cursor: pointer;
`;

const options = {
  settings: {
    // overlayColor: 'rgb(25, 136, 124)',
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  buttons: {
    // backgroundColor: '#1b5245',
    iconColor: 'rgba(126, 172, 139, 0.8)',
  },
  caption: {
    showCaption: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
};

const slideImages = [
  'http://d3kvuzlw39or84.cloudfront.net/articles/buttner/buttner-paper_1.png',
  'http://d3kvuzlw39or84.cloudfront.net/articles/buttner/buttner-paper_2.png',
  'http://d3kvuzlw39or84.cloudfront.net/articles/buttner/buttner-paper_3.png',
  'http://d3kvuzlw39or84.cloudfront.net/articles/buttner/buttner-paper_4.png',
  'http://d3kvuzlw39or84.cloudfront.net/articles/buttner/buttner-paper_5.png',
  'http://d3kvuzlw39or84.cloudfront.net/articles/buttner/buttner-paper_6.png',
];

const ValidationStudies = () => {
  return (
    <Layout>
      <Text
        variant="subheading"
        width={1}
        py={[2, 4]}
        sx={{
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'normal',
        }}
      >
        Validation Studies
      </Text>

      <ResponsiveMasonry
        columnsCountBreakPoints={{
          350: 1,
          750: 2,
          900: 3,
          1280: 4,
          1440: 5,
          1680: 6,
        }}
      >
        <Masonry>
          {slideImages.map((image) => (
            <SRLWrapper options={options}>
              <Img
                key={image}
                src={image}
                alt={`uvc-${image}`}
                style={{
                  padding: '0.6rem 0',
                  margin: '0 auto',
                  // maxHeight: '500px',
                  // width: '100%',
                  maxWidth: '300px',
                  display: 'block',
                }}
              />
            </SRLWrapper>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </Layout>
  );
};

export default ValidationStudies;
